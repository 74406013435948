import clsx from 'clsx'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'

import squareBabyPic from '../public/images/square-baby.png'
import squareBobPic from '../public/images/square-bob.png'
import squareCeremonyPic from '../public/images/square-ceremony.png'
import squareTestimonialsPic from '../public/images/square-testimonials.png'
import squareTraditionsPic from '../public/images/square-traditions.png'

export default function Home(): JSX.Element {
  return (
    <div>
      <Head>
        <title>
          Robert Karasov - Minneapolis Mohel :: Jewish Circumcision :: Ritual
          Circumcision
        </title>
        <meta
          content="Robert (Bob) Karasov is a pediatrician who performs the Jewish Circumcision ritual. He is known as a Mohel and lives in the Minneapolis, St. Paul area."
          name="description"
        />
      </Head>
      <main>
        <div className="flex justify-center w-content max-w-full my-4 mx-auto px-4">
          <div className="grid grid-cols-5 md:grid-cols-4 sm:grid-cols-3 gap-4 justify-items-center">
            <GridBox
              href="/contact"
              image={squareBobPic}
              imageAlt="Contact background image"
              title="contact"
            />
            <GridBox color="purple" />
            <GridBox color="light-blue" hide="sm:hidden" />
            <GridBox color="blue" hide="md:hidden" />
            <GridBox
              href="/ceremony"
              image={squareCeremonyPic}
              imageAlt="Ceremony background image"
              title="the ceremony"
            />

            <GridBox color="purple" />
            <GridBox color="light-blue" hide="md:hidden" />
            <GridBox
              href="/need"
              image={squareBabyPic}
              imageAlt="What you need background image"
              title="what you need"
            />
            <GridBox color="teal" />
            <GridBox color="blue" hide="sm:hidden" />

            <h1 className="col-span-5 md:col-span-4 sm:col-span-3 my-8 text-teal text-5xl font-semibold tracking-wide text-center">
              robert karasov, m.d.
            </h1>

            <GridBox color="light-blue" hide="md:hidden" />
            <GridBox
              href="/tradition"
              image={squareTraditionsPic}
              imageAlt="Traditions background image"
              title="traditions"
            />
            <GridBox color="teal" />
            <GridBox color="purple" hide="sm:hidden" />
            <GridBox color="blue" />

            <GridBox color="blue" hide="sm:hidden" />
            <GridBox color="teal" hide="md:hidden" />
            <GridBox color="purple" />
            <GridBox
              href="/testimonials"
              image={squareTestimonialsPic}
              imageAlt="Testimonials background image"
              title="testimonials"
            />
            <GridBox color="light-blue" />
          </div>
        </div>
      </main>
    </div>
  )
}

const GridBox = ({
  color,
  hide = '',
  href,
  image = undefined,
  imageAlt = '',
  title,
}: {
  color?: 'blue' | 'light-blue' | 'purple' | 'teal'
  hide?: 'md:hidden' | 'sm:hidden' | ''
  href?: string
  image?: string
  imageAlt?: string
  title?: string
}): JSX.Element => {
  return (
    <div
      className={clsx(
        'relative w-32 h-32 sm:w-28 sm:h-28 rounded-2xl overflow-hidden',
        hide,
        {
          [`bg-${color}`]: !image,
          'bg-gradient-to-t from-pink to-transparent': image,
        }
      )}
    >
      {image && (
        <div className="absolute top-0 left-0 -z-10">
          <Image alt={imageAlt} src={image} />
        </div>
      )}
      {href && title && (
        <Link className="block relative w-full h-full" href={href}>
          <span className="absolute bottom-0 left-0 w-full mb-4 text-white sm:text-sm text-center">
            {title}
          </span>
        </Link>
      )}
    </div>
  )
}
